<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="parseTableAction({ actionType: 'new-tract' })">
  <v-container fluid>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs(true)" @close="resetDialogs(true)" maxWidth="90vw">
      <TractForm
      :tractId="focusedTract.tractId"
      @tract-changed="resetDialogs(true)"
      v-if="creating || editing"
      />
      <ConfirmDelete
        :title="$t('tract')"
        v-if="deleting"
        @delete="deleteItem"
        @cancel-delete="resetDialogs()"/>
      <ConfirmDialog
      v-if="pausing"
      width="550px"
      :title="$t(focusedTract.paused ? 'unpauseTractX' : 'pauseTractX', { x: focusedTract.name })"
      :body="focusedTract.paused ? $t('unpauseTractMessage') : $t('pauseTractMessage')"
      :confirmText="focusedTract.paused ? $t('unpause') : $t('pause')"
      color="primary"
      @confirm="toggleTractPause"
      @cancel="resetDialogs"
      data-testid="pause-tract-dialog"/>
      <TractDetail
      :tabToOpen="identifyTractStatus.nearestTract ? 1 : 0"
      :tractId="focusedTract.tractId"
      @edit-tract="editTract"
      :distanceFromTract="identifyTractStatus.distance"
      @changed="refreshTracts()"
      v-if="tractDetail"/>
    </Dialog>
    <TractTable
      @tract-action="parseTableAction"
      @refresh-tracts="refreshTracts"
      @filter-applied="filterChanged"
      :propFilter="tractFilter"
      @identify-tract="identifyTractPressed"
    />
    <Dialog :stateId="showCruiseDataDialogId" maxWidth="400px">
      <ConfirmDialog
      :title="$t('addCruiseDataTitle')"
      :body="$t('addCruiseDataBody')"
      :confirmText="$t('addNow')"
      color="primary"
      @confirm="showTractCruiseDataDialog"
      @cancel="resetDialogs"
      data-testid="add-cruise-data-dialog"/>
    </Dialog>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { TractFilter } from '@/model/Tract.js'
import { uniqueDialogId } from '../utils/componentHelpers'
import * as atlas from 'azure-maps-control'
import { numberWithCommas } from '@/utils/NumericMutations'
import { UserClaims } from '../utils/Enumerations'

export default {
  name: 'Tracts',

  components: {
    TractForm: () => import('@/components/tract/tract-form/TractForm'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue'),
    TractTable: () => import('@/components/tract/TractTable.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Dialog: () => import('@//components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  computed: {
    ...mapGetters('tract', ['allTracts']),
    ...mapGetters('global', ['routerJump'])
  },

  data: () => ({
    dialogId: uniqueDialogId('tracts'),
    showCruiseDataDialogId: uniqueDialogId('tracts-show-cruise-data'),
    deleting: false,
    editing: false,
    creating: false,
    pausing: false,
    tractDetail: false,
    showCruiseData: false,
    confirmedCruiseData: false,
    tractFilter: new TractFilter({ useLocalStorageKey: true }),
    focusedTract: {},
    identifyTractStatus: {
      nearestTract: undefined,
      distance: undefined,
      error: undefined
    },
    UserClaims
  }),

  async created () {
    await this.refreshTracts()

    if (this.routerJump !== undefined) {
      await this.handleRouterJump(this.routerJump)
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeAllDialogs', 'closeDialogsAtOrAbove']),
    ...mapActions('tract', ['fetchTracts', 'fetchTract', 'deleteTract', 'getTractWithId', 'updateTract', 'fetchTractsOnce', 'fetchTractLandowners']),
    ...mapMutations('global', ['setRouterJump']),
    ...mapMutations('snackbar', ['setSnackError', 'setSnack']),
    parseTableAction (action) {
      this.resetDialogs(false)

      if (action.tract) {
        this.focusedTract = action.tract
      }

      switch (action.actionType) {
        case 'tract-detail':
          this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
          this.tractDetail = true
          break
        case 'edit-tract':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.editing = true
          break
        case 'delete-tract':
          this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
          this.deleting = true
          break
        case 'new-tract':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.creating = true
          break
        case 'pause-tract':
          this.openOrUpdateDialog({ id: this.dialogId, width: '550px' })
          this.pausing = true
          break
        default:
          break
      }
    },

    editTract (tract, readonly = false) {
      this.resetDialogs()
      this.focusedTract = tract
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
      if (readonly) {
        this.tractDetail = true
      } else {
        this.editing = true
      }
    },

    async handleRouterJump (jump) {
      if (jump !== undefined) {
        switch (this.routerJump.sourceView) {
          case 'Contracts':
          case 'Tickets':
          case 'UpdateExpirationDate':
          case 'StandingTimber':
          case 'CommittedTimber':
          case 'TimberPortfolio':
            const tract = this.allTracts
              .find(t => t.tractId === this.routerJump.sourceObject.tractId) ??
                await this.fetchTract(this.routerJump.sourceObject.tractId)
            if (tract !== undefined) {
              this.$nextTick(() => {
                this.editTract(tract, jump.options?.readonly ?? false)
              })
            }
        }
        this.setRouterJump(undefined)
      }
    },

    filterChanged (tractFilter) {
      this.tractFilter = tractFilter
      this.refreshTracts()
    },

    async toggleTractPause () {
      await this.updateTract({
        ...this.focusedTract,
        paused: !this.focusedTract.paused,
        tractTypeId: this.focusedTract.type.tractTypeId,
        foresterUserId: this.focusedTract.foresterUser?.applicationUserId,
        certificationIds: this.focusedTract.tractCertifications.map(tc => tc.certificationId),
        loggerIds: this.focusedTract.loggers.map(l => l.accountId),
        userIds: this.focusedTract.users.map(u => u.applicationUserId)
      })

      this.resetDialogs(false)
    },

    showTractCruiseDataDialog () {
      this.confirmedCruiseData = true
      this.showCruiseData = false
      this.editing = true
      this.openOrUpdateDialog({ id: this.showCruiseDataDialogId, width: '400px' })
    },

    async refreshTracts (closeTractForm) {
      try {
        await this.fetchTracts(this.tractFilter)
        if (closeTractForm) this.resetDialogs()
      } catch (e) {
        console.error(e)
      }
    },

    resetDialogs (refresh) {
      this.closeDialogsAtOrAbove([this.dialogId, this.showCruiseDataDialogId])
      this.dialog = false
      this.editing = false
      this.tractDetail = false
      this.deleting = false
      this.creating = false
      this.pausing = false
      this.showCruiseData = false
      this.confirmedCruiseData = false
      this.focusedTract = {}
      this.identifyTractStatus = {
        nearestTract: undefined,
        distance: undefined,
        error: undefined
      }

      if (refresh) {
        this.refreshTracts()
      }
    },

    async deleteItem () {
      await this.deleteTract(this.focusedTract.tractId)
      await this.refreshTracts()
      this.resetDialogs()
    },

    async showCruiseDataDialog (tractId) {
      await this.refreshTracts()
      const response = await this.getTractWithId(tractId)
      this.showCruiseData = true
      this.focusedTract = response
    },

    async identifyTractPressed () {
      this.identifyTractStatus = {
        nearestTract: undefined,
        distance: undefined,
        error: undefined
      }

      const handlePosition = async (position) => {
        const nearestTract = tracts.reduce((nearestTract, currentTract) => {
          const distance = atlas.math.getDistanceTo(
            atlas.data.Position(currentTract.spot.latitude, currentTract.spot.longitude),
            atlas.data.Position(position.coords.latitude, position.coords.longitude),
            'miles'
          )
          if (nearestTract.distance === undefined || distance < nearestTract.distance) {
            nearestTract = { tract: currentTract, distance: distance }
          }
          return nearestTract
        }, { tract: undefined, distance: undefined })

        this.identifyTractStatus.nearestTract = nearestTract.tract
        this.identifyTractStatus.distance = numberWithCommas(nearestTract.distance, 1)

        this.editing = false
        this.focusedTract = this.identifyTractStatus.nearestTract
        this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
        this.tractDetail = true
      }

      const handleGeolocationError = async (error) => {
        this.identifyTractStatus.error = error.message
        this.setSnackError(this.$t('errorGettingLocationMessage', { message: error.message }))
      }

      const tracts = await this.fetchTractsOnce(new TractFilter({ includeInitiated: true, includeCruised: true, includeExecuted: true, includeActive: true, includeClosed: true, includeArchived: true }))

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handlePosition, handleGeolocationError)
      } else {
        this.identifyTractStatus.error = this.$t('geolocationNotSupported')
      }
    }
  }
}
</script>

<style>
#progress-bar-table {
  position: absolute;
  left: 50%;
  top: 30%;
}
</style>
